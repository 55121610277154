import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DataRequest, DataResponse, DynamicLink, Agent, DynamicField, Range, AllocateRangesRequest, AgentTracingCoordinat, AgentBlacklistDto, AgentBlacklistHistoryDto } from '../../dto/dtos';
import { HttpDataService } from '../http/http-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxonService } from '../axon-service';
import { FilterOption } from '../../component/material-table/material-table.component';
import { AxonUtils } from '../../utils/axon-utils';
import { environment, SectionUtils } from '../../../environments/environment';
import { Notifier } from '../../utils/notifier';
import { Section } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Entry point for components requiring access to agent data
 */
export class AgentService extends AxonService {

    private httpService: HttpDataService;

    constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
        super( _httpClient, _authService, _notifier );
    }

	public getTracingData(agentId: number, start: string, end: string): Observable<AgentTracingCoordinat[]> {
		// const start = this.datePipe.transform(startDate, 'yyyy-MM-dd');
		// const end = this.datePipe.transform(endDate, 'yyyy-MM-dd');
		const httpService = new HttpDataService(this.httpClient, this.authService, '/user', this.notifier);
		return httpService.doGet(`/${agentId}/tracing/${start}/${end}`);
	}
    public getAgentSims(axonId: number, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: axonId,
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, `/getsims`, '/user');
    }

    public unlinkAgentSim(agentId: number, serial: string): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierStr: serial,
            identifierNum: agentId,
        };
        return this.getDataResponse(dataRequest, `/unlinksim`, '/user');
    }

    public agentSendSMSDownloadLink(agentId: number): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        const rootpath = "/user";

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return new Promise<DataResponse>(resolve => {
            this.httpService.sendData(dataRequest, '/sendsmslink').pipe(
                map(response => {
                    return response;
                })
            ).subscribe(result => {
                resolve(result);
            });
        });
    }

    /**
     * Fetches agents for table data
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getAgents(sort: string, order: string, pageNum: number, pageMax: number, filterOptions: FilterOption[], dmsId?: number): Observable<DataResponse> {

        const dataRequest: DataRequest = {
            pageNum: pageNum,
            pageMax: pageMax,
            sort: sort,
            order: order,
            filterOptions: filterOptions,
			dmsNodeId: dmsId
        };

        return this.getDataResponse(dataRequest, '/getuserdata');
    }

    /**
     * Fetches a single agent by agent ID for form data.
     * @param path
     * @param identifierNum
     */
    public getAgentFormDataByAgentId( sectionId: number, identifierNum: number ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            sectionId: sectionId,
            cardId: 0,
            identifierNum: identifierNum
        };

        return this.getDataResponse(dataRequest, '/agentid', '/dynamicdata');
    }

    /**
     * This will get dynamic fields for location and is used when editing the agents locations
     * @param sectionId
     */
    public getSitesFormDataByCompanyIdAgentId( sectionId: number ): Observable<DataResponse> {

        const dataRequest: DataRequest = {
            sectionId: sectionId,
            agentId: this.authService.getAuthAgent().agentId,
            identifierNum: environment.company_id
        };

        return this.getDataResponse(dataRequest, '/companyid', '/dynamicdata');
    }

    /**
     * Fetches invidiual agent by agent ID
     * @param agentId
     */
    public getAgent(agentId: number): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        return this.getDataResponse(dataRequest, '/getuser');
    }

	public getAgentBlacklistHistoryData(agentId: number) : Observable<DataResponse> {
		const httpService = new HttpDataService(this.httpClient, this.authService, '/blacklist', this.notifier);
		return httpService.doGet<DataResponse>(`/${agentId}/agent/history`);		
	}

	public getAgentBlacklistStatus(agentId: number): Observable<DataResponse> {
		const httpService = new HttpDataService(this.httpClient, this.authService, '/blacklist', this.notifier);
		return httpService.doGet<DataResponse>(`/${agentId}/agent/status`);
	}

	public blacklistAgent(blacklistDto: AgentBlacklistDto): Observable<DataResponse> {
		const httpService = new HttpDataService(this.httpClient, this.authService, '/blacklist', this.notifier);
		return httpService.doPost<DataResponse>(`/agent/blacklist`, blacklistDto);
	}

	public unBlacklistAgent(agentId: number): Observable<DataResponse> {
		const httpService = new HttpDataService(this.httpClient, this.authService, '/blacklist', this.notifier);
		return httpService.doGet<DataResponse>(`/${agentId}/agent/unblacklist`);		
	}

    /**
     * Fetches multiple agents by agent IDs
     * @param agentIds
     */
    public getAgentsByIds(agentIds: string[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierStringsList: agentIds
        };

        return this.getDataResponse(dataRequest, '/getusers');
    }

    /**
     * Fetches invidiual agent by agent ID
     * @param agentId
     */
    public getApprovalAgentDetails(agentId: number): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        return this.getDataResponse(dataRequest, '/getapprovalagentdetails');
  }

    /**
     * Fetches the table data for agent's approval history
     * @param agentId
     * @param pageNum
     * @param pageMax
     * @param filterOptions
     */
    public getAgentApprovalHistoryData(agentId: number, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            pageNum: pageNum,
            pageMax: pageMax,
            filterOptions: filterOptions
        };

        return this.getDataResponse(dataRequest, '/getapprovalhistory');
    }

	/**
     * Fetches Audit Trail (Device history) for agent by agent ID
     * @param agentId
     */
    public getAgentDeviceHistory(agentId: number): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        return this.getDataResponse(dataRequest, '/getagentdevicehistory');
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public getAvailablePermissions(agentId: number): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        return this.getDataResponse(dataRequest, '/getavailablepermissions');
    }

    /**
     * Fetches Agent's ranges (MSISDN and ICCID)
     * @param agentId
     */
    public getAgentRanges(agentId: number): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId
        };

        return this.getDataResponse(dataRequest, '/getagentranges');
    }

    /**
     * Sends command to server to add the given range to the agent
     * @param agentId
     */
    public addAgentRange(agentId: number, range: Range): Observable<DataResponse> {

        const list = new Array<Range>();
        list.push( range );

        const dataRequest: AllocateRangesRequest = {
            identifierNum: agentId,
            addedList: list
        };

        return this.getDataResponse(dataRequest, '/allocateranges');
    }

    /**
     * Sends command to server to edit the given range for the agent
     * @param agentId
     */
    public editAgentRange(agentId: number, range: Range): Observable<DataResponse> {

        const list = new Array<Range>();
        list.push( range );

        const dataRequest: AllocateRangesRequest = {
            identifierNum: agentId,
            editedList: list
        };

        return this.getDataResponse(dataRequest, '/allocateranges');
    }

    /**
     * Sends command to server to delete the given range for the agent
     * @param agentId
     */
    public deleteAgentRange(agentId: number, range: Array<Range>): Observable<DataResponse> {

        const dataRequest: AllocateRangesRequest = {
            identifierNum: agentId,
            deletedList: range
        };

        return this.getDataResponse(dataRequest, '/allocateranges');
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentBlock(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/block').subscribe(result => {
                resolve(result);
            });
        });
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentUnblock(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/unblock').subscribe(result => {
                resolve(result);
            });
        });
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentEnable(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/enable').subscribe(result => {
                resolve(result);
            });
        });
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentDisable(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/disable').subscribe(result => {
                resolve(result);
            });
        });
	}

	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentReset(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/reset').subscribe(result => {
                resolve(result);
            });
        });
    }
	/**
     * Fetches Avaliable permissions for agent by agent ID
     * @param agentId
     */
    public agentResetAndGet(agentId: number, reasonValue?: string): Promise<DataResponse> {
        const dataRequest: DataRequest = {
            identifierNum: agentId,
            reason: reasonValue
        };

		    return new Promise<DataResponse>(resolve => {
            return this.getDataResponse(dataRequest, '/reset_with_data').subscribe(result => {
                resolve(result);
            });
        });
    }

    public saveFormData( sectionId: number, identifierNum: number, dynamicLink: DynamicLink ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            sectionId: sectionId,
            identifierNum: identifierNum,
            agentId: this.authService.getAuthAgent().agentId,
            dynamicLink: dynamicLink
        };

        return this.getDataResponse(dataRequest, '/save', '/dynamicdata');
    }

    /**
     * Pass a dynamic link in and checks if it passes uniqueness validation eg. An Agent's Username
     * Add an identifier string to exclude the current record being editted in the check
     * @param dynamicLink
     */
    public validateUnique( dynamicField: DynamicField, value: string, identifierNum?: number ): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            agentId: this.authService.getAuthAgent().agentId,
            dynamicField: dynamicField,
            value: value
        };

        if (identifierNum !== undefined) {
            dataRequest.identifierNum = identifierNum;
        }

        return this.getDataResponse(dataRequest, '/unique', '/dynamicdata');
    }

    public bulkAdd( path?: string, nameValuesList?: any, identifierStringsList?: any ): Observable<DataResponse> {

        let dataRequest: DataRequest = {};

        if (path !== null) {
            dataRequest = { ...dataRequest, bulkAction: path };
        }

        if (nameValuesList !== null) {
            // const list = JSON.stringify(nameValuesList);
            dataRequest = { ...dataRequest, nameValuesList: nameValuesList };
        }

        if (identifierStringsList !== null) {
            dataRequest = { ...dataRequest, identifierStringsList };
        }

        return this.getDataResponse(dataRequest, '/bulkagentaction', '/user');
    }

    /**
     * Helper to do bulk block/unblock/enable/disable/reset actions
     * @param path
     * @param agentList
     * @param agentUsernamesList
     * @param reason
     * @returns Promise with string results of 'success', 'partial' and 'failed'
     */
    public bulkAction(
      path: string,
      agentList?: number[],
      agentUsernamesList?: string[],
      reason?: string): Promise<DataResponse> {

        let dataRequest: DataRequest = { bulkAction: path, reason: reason };

        if (agentList !== null && agentList !== undefined) {
            dataRequest = { ...dataRequest, identifierStringsList: agentList.map(String) };
        }


        if (agentUsernamesList !== null && agentUsernamesList !== undefined) {
            dataRequest = { ...dataRequest, agentUsernamesList };
        }

        console.log(dataRequest);

        return new Promise<any>(resolve => {
            this.getDataResponse(dataRequest, '/bulkagentaction', '/user').subscribe(result => {
                if (result.error === undefined && result.success === true) {
                    resolve(result);
                } else if (result.error && result.success === true) {
                    /* Some records were not actioned but some were */
                    resolve(result);
                } else {
                    resolve(result);
                }
            });
        });
    }

    flagAgent(agent: Agent, reason: string): Promise<any> {
        const dataRequest: DataRequest = {
            identifierNum: agent.agentId,
            agentId: this.authService.getAuthAgent().agentId,
            reason: reason
        };

        return new Promise<any>(resolve => {
            this.getDataResponse(dataRequest, '/flagagent').subscribe(result => {
                if (result.error === undefined && result.success === true) {
                    resolve('success');
                } else {
                    resolve('failed');
                }
            });
        });
    }

    unflagAgent(agent: Agent, reason: string): Promise<any> {
        const dataRequest: DataRequest = {
            identifierNum: agent.agentId,
            agentId: this.authService.getAuthAgent().agentId,
            reason: reason
        };


        return new Promise<any>(resolve => {
            this.getDataResponse(dataRequest, '/unflagagent').subscribe(result => {
                if (result.error === undefined && result.success === true) {
                    resolve('success');
                } else {
                    resolve('failed');
                }
            });
        });
    }

    /**
     * Download all agents details
     */
    downloadAgentsCSV(): Observable<DataResponse> {
        const dataRequest: DataRequest = {
            // DUMMY
        };

        return this.getDataResponse(dataRequest, '/getagentscsv');
    }

    /**
     * Helper to get the observable containing the response
     * @param dataRequest
     * @param subpath - the subpath following the rootpath
     * @param rootpath - if null, will be set to /user
     */
    public getDataResponse( dataRequest: DataRequest, subpath: string, rootpath?: string ): Observable<DataResponse> {

        /* If not defined, set to /user i.e. the default root path */
        if ( !rootpath ) {
            rootpath = '/user';
        }

        this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
        return this.httpService.sendData(dataRequest, subpath).pipe(
            map(response => {
                if ( response.data ) {
                    if ( response.data.birthdate ) {
                        response.data.birthdate = AxonUtils.getDate(response.data.birthdate);
                    }
                    if ( response.data.datetime ) {
                        response.data.datetime = AxonUtils.getDate(response.data.datetime);
                    }
				}

                /* Put the elementId on for the tables, to allow for maintaining selected/checkbox state */
                if (response.dataList) {
                    response.dataList.forEach(row => {
                        row.elementId = row.agentId;
                    });
                }
                return response;
            })
        );
    }
}
