import { DmsNodeInfoDto } from '../app/dto/dtos';
import { FormControl } from "@angular/forms";
import { FilterOption } from "../app/component/material-table/material-table.component";
import { AbstractMomokashUser, Card, Customer, GroupLists, Msisdn } from "../app/dto/dtos";
import { CardTableColumn, SimTableColumn } from "../app/model/models";
import { ReportCard } from "../app/report/report-list.component";
import { GroupListsManager } from "../app/settings/grouplists/group-lists-manager";
import { AxonUtils } from "../app/utils/axon-utils";
import { GROUPLIST, SECTION_MAP, Section, buildDefaultMap } from "../app/utils/constants";
import { Permission as PermissionEnum } from '../app/utils/constants';

export const environment = {
  production: false,
  company_id: 22,
  agent_last_location_map: false,
  url: '/axonapi',
  metabase_url: '/metabase_api',
  metabase_secret_key: 'ea60242448bd5611c4660d9602ebedde8b0135d08ef06c5e9fc50cbcff615969',
  metabase_token_expiry: 720, //In minutes
  metabase_refresh_time: 5, //In minutes
  metabase_refresh_enabled: true,
  show_pdf_in_frame: true,
  use_msisdns: true,
  customers_load_table_before_filters_set: true,
  customers_show_archive_data: false,
  agents_show_approval_history: true,
  agents_show_tracing: true,
  show_orgs: false,
  disabledMemorableQuestions: true,
  includeTpRequestInSubmissionHistory: true,
  show_rejection_reason_dropdown_window: true,
  show_rejection_non_existent_image_placeholder: false,
  show_reject_final: false,
  show_org_management: false,
  show_override_approval: false,
  show_approval_review: false,
  customers_allow_edit: true,
  storage: 'sessionStorage',
  show_download_pdf: false,
  show_download_legal_pdf: false,
  open_approval_form_in_edit_mode: false,
  save_and_approve_mode: false,
  show_add_sim_linked_org: false,
  timezone: '+0',
  show_sim_swap: false,
  change_ownership: false,
  reports_show_org_selector: false,
  show_management: false,
  merchant_reg: false,
  churn_close_ecw_account: false,
  enable_add_customer: true,
  customer_text: "Voters",
  users_text: "Operators",
  show_churn_menu: false,
  show_approvals_menu: true,
  show_candidates_menu: true,
  show_elections_dashboard: true,
  show_approval_review_menu: false,
  show_sims_card_in_approval: false,
  show_sims_card_in_customer: false,
};

export interface ServiceTypeDefinition {
	/*
	MSISDN;MSISDN|msisdn|9|9;

	MSISDN = Label in the dropdown of service types
	MSISDN = Label of the input
	msisdn = validation type. (msisdn must use grouplists 14 for prefixes)
	9 = min length
	9 = max length
	*/
	dropdownLabel: string;
	serviceTypeInput: Array<ServiceTypeInput>;
}

export interface ServiceTypeInput {
	inputLabel: string;
	validationType: string;
	min: number;
	max: number;
	formControl?: FormControl;
}

export class SectionUtils {

	public static getSectionId(section: Section): number{

		if ( SECTION_MAP.size === 0 ) {
			this.buildMap();
		}

		return SECTION_MAP.get(section);
	}

	public static getSectionById(sectionId: number): Section {

		if ( SECTION_MAP.size === 0 ) {
			this.buildMap();
		}

		let ret = 0;
		SECTION_MAP.forEach((value: number, key: Section) => {
			if ( value.valueOf() === sectionId.valueOf() ) {
				ret = key;
			}
		});

		return ret;
	}

	private static buildMap() {
		SECTION_MAP.set(Section.VOTING_NEW_VOTER   	   , 201);
		SECTION_MAP.set(Section.VOTING_EDIT_VOTER      , 202);
		SECTION_MAP.set(Section.VOTING_NEW_CANDIDATE   , 203);
		SECTION_MAP.set(Section.VOTING_EDIT_CANDIDATE  , 204);
		SECTION_MAP.set(Section.VOTING_NEW_ATTENDEE    , 205);
		SECTION_MAP.set(Section.VOTING_EDIT_ATTENDEE   , 206);

		// TODO: This method should be moved into environment
		buildDefaultMap();
	}

	public static getCustomerSectionId(customer: Customer): Section {
		switch (customer.msisdns[0].serviceType) {
			case 'VOTER':
				return Section.VOTING_NEW_VOTER;
			default: 
				// TODO add other service types
				return Section.VOTING_NEW_VOTER;
		}
	}

	public static getCustomerSectionIdByContractAndType(customerType: string, contractType: string): Section {

		return Section.NEW;
	}

	public static getModifyCustomerSectionIdByContractAndType(customer: Customer): Section {

		switch (customer.msisdns[0].serviceType) {
			case 'VOTER':
				return Section.VOTING_EDIT_VOTER;
			default: 
				// TODO add other service types
				return Section.VOTING_EDIT_VOTER;
		}
	}

	public static getMomokashSectionIdByType(type: string, action?: string): Section {

		console.log('Finding section for type [' + type + '] and action [' + action + ']');

		if ( action && action === 'CONFIG') {
			switch (type) {
				case 'UPDATE POS NUMBER':
					return Section.MOMOKASH_UPDATE_POS_NUMBER;
				case 'UPDATE ACCOUNT NUMBER':
					return Section.MOMOKASH_UPDATE_AGENT_ACCOUNT;
				case 'ATTACH HIERARCHY':
					return Section.MOMOKASH_ATTACH_AGENT_HIERARCHY;
			}
			console.log('Unable to determine appropriate Section for momokash action [' + action + '] and type [' + type + ']');
			return null;
		}

		switch (type) {
			case 'AGENT':
				return Section.MOMOKASH_AGENT_CREATION;
			case 'HANDLER / POS':
				return Section.MOMOKASH_HANDLER_POS_CREATION;
			case 'MERCHANT':
				return Section.MOMOKASH_MERCHANT_CREATION;
		}

		console.log('Unable to determine appropriate Section for momokash user type [' + type + ']');

		return null;
	}

	public static getModifyMomokashUserSectionId(momokashUser: AbstractMomokashUser): Section {
		switch (momokashUser.type) {
			case 'AGENT':
				return Section.MOMOKASH_AGENT_MODIFY;
			case 'HANDLER / POS':
				return Section.MOMOKASH_HANDLER_POS_MODIFY;
			case 'MERCHANT':
				return Section.MOMOKASH_MERCHANT_MODIFY;
		}

		console.log('Unable to determine appropriate Modify Section for momokash user type [' + momokashUser.type + ']');

		return null;
	}
}

export class NewSimUtils {

	public static getServiceTypeList(groupListsMgr: GroupListsManager, customerType: string, contractType: string): Array<ServiceTypeDefinition> {

		let serviceTypeList = groupListsMgr.getGroupListIdLang(GROUPLIST.IDX_SERVICE_TYPE_PREPAID, "en");
		// if ( customerType === 'NEW' ) {
		// 	serviceTypeList = groupListsMgr.getGroupListIdLang(GROUPLIST.IDX_SERVICE_TYPE_POSTPAID, "en");
		// }

		const serviceTypes = new Array<ServiceTypeDefinition>();
		for (const st of serviceTypeList ) {

			/*
			MSISDN;MSISDN|msisdn|9|9;

			MSISDN = Label in the dropdown of service types
			MSISDN = Label of the input
			msisdn = validation type. (msisdn must use grouplists 14 for prefixes)
			9 = min length
			9 = max length
			*/
			const labelAndSettings: Array<string> = st.text.split(";");

			const settings = new Array< string >();
			for ( let i = 1; i < labelAndSettings.length; i++) {
				if ( labelAndSettings[i].length > 0 ) {
					settings.push(labelAndSettings[i]);
				}
			}

			const serviceTypeInputs = new Array< ServiceTypeInput >();
			for ( const str of settings ) {
				const inputSettings: Array<string> = str.split("|");

				const serviceTypeInput: ServiceTypeInput = {
					inputLabel: inputSettings[0],
					validationType: inputSettings[1],
					min: Number(inputSettings[2]),
					max: Number(inputSettings[3]),
					formControl: new FormControl()
				}

				serviceTypeInputs.push(serviceTypeInput);

			}

			const serviceType: ServiceTypeDefinition = {
				dropdownLabel: labelAndSettings[0],
				serviceTypeInput: serviceTypeInputs
			}

			serviceTypes.push(serviceType);
		}

		console.log('------------ ALL SERVICE TYPES ------------------:', serviceTypes);

		return serviceTypes;
	}

	public static showMsisdnInventorySelection(customerType: string) {
		return false;
	}

	public static getSimTableColumns() {
		const simColumns = [
			// tslint:disable: max-line-length
			{ columnDef: 'checkbox'          , header: ''               , cell: (element: Msisdn) => `${element.selected}`, isCheckboxEventEmitter: true },
			{ columnDef: 'msisdn'            , header: 'MSISDN'         , cell: (element: Msisdn) => `${element.msisdn}` },
			{ columnDef: 'iccid'             , header: 'ICCID'          , cell: (element: Msisdn) => `${element.iccid}` },
			{ columnDef: 'serviceType'       , header: 'Service'        , cell: (element: Msisdn) => `${element.serviceType}` },
		];

		return simColumns;
	}

	public static displayContractType() {
		return false;
	}

	public static runMsisdnPrefixCheck(serviceType: ServiceTypeDefinition) {
		return serviceType.dropdownLabel.startsWith('MSISDN');
	}
}

export class MetabaseReports {
	public static buildList(): Array<ReportCard> {
        let reportList = new Array();

        /* Summary Reports */
        this.buildSummaryList(reportList);

		return reportList;

    }
    private static buildSummaryList(list: Array< ReportCard >) {

        list.push(
            {
                name: "Summary Reports",
                list: [
                    {
                        name: "Total Registration Per Day", id: 143, permission: PermissionEnum.REPORT_TOTAL_REG_PER_DAY
                    },
                    {
                        name: "Approvals Per Day", id: 144, permission: PermissionEnum.REPORT_APPROVALS_PER_DAY
                    },
                    {
                        name: "Rejections Per Day", id: 145, permission: PermissionEnum.REPORT_REJECTION_PER_DAY
                    }
                ]
            }
        );

    }
}

export const AgentSearchFilters: FilterOption[] = [
	{ order: 0, filter: 'Agent ID', type: 'text' },
	{ order: 1, filter: 'Name', type: 'text' },
	{ order: 3, filter: 'Last Name', type: 'text', dynamicAlias: 'Surname' },
	{ order: 4, filter: 'MSISDN', type: 'text' },
	{ order: 5, filter: 'Username', type: 'text' },
	{ order: 6, filter: 'Status', type: 'agentStatus' },
	{ order: 9, filter: 'Role', type: 'select_role' },
	{ order: 10, filter: 'Last Accessed', type: 'date' },
];

export const CustomerSearchFilters: FilterOption[] = [
	{ order: 0, filter: 'Account Number', type: 'text' },
	{ order: 1, filter: 'MSISDN', type: 'text' },
	{ order: 2, filter: 'ID Type', type: 'customerIDType' },
	{ order: 3, filter: 'Updated From', type: 'dateSince' },
	{ order: 4, filter: 'Approval Status', type: 'agentApprovalHistoryStatus' },
	{ order: 5, filter: 'Customer Name', type: 'text' },
	{ order: 6, filter: 'Customer Middle Name', type: 'text' },
	{ order: 7, filter: 'Customer Last Name', type: 'text' },
	{ order: 8, filter: 'ID Number', type: 'text' },
	{ order: 9, filter: 'ICCID', type: 'text' },
	{ order: 12, filter: 'Agent Username', type: 'text' },
	{ order: 13, filter: 'Agent MSISDN', type: 'text' },
	{ order: 14, filter: 'Contact Number', type: 'text' },
	{ order: 15, filter: 'Status', type: 'text' },
];

export const CustomerIdTypeFilterOptions = [
	"Driving Licence",
	"Trade licence",
	"Employee ID",
	"Other",
	"House Number",
	"TIN No.",
	"NationalID",
	"Corporate Letter",
	"KebeleID",
	"Passport",
	"Student ID"
];

export const MomokashSearchFilters: FilterOption[] = [
	{ order: 0, filter: 'Axon ID', type: 'text' },
	{ order: 1, filter: 'MSISDN', type: 'text' },
	{ order: 2, filter: 'Username', type: 'text' },
	{ order: 3, filter: 'Approval Status', type: 'agentApprovalHistoryStatus' },
	{ order: 4, filter: 'Updated From', type: 'dateSince' },
	{ order: 5, filter: 'Agent Username', type: 'text' },
	{ order: 6, filter: 'Agent MSISDN', type: 'text' },
];

export const DmsSearchFilters: FilterOption[] = [];

export const MomokashFailedActivationSearchFilters: FilterOption[] = [
	{ order: 0, filter: 'Account Number', type: 'text' },
	{ order: 1, filter: 'MSISDN', type: 'text' },
	{ order: 2, filter: 'Name', type: 'text' },
	{ order: 3, filter: 'Surname', type: 'text' },
	{ order: 4, filter: 'Approval Status', type: 'agentApprovalHistoryStatus' },
	{ order: 5, filter: 'Agent Username', type: 'text' },
	{ order: 6, filter: 'Agent MSISDN', type: 'text' },
];

export const ApprovalBulkFilters: FilterOption[] = [
	{ order: 0, filter: 'Account Number'  , type: 'text' },
	{ order: 1, filter: 'MSISDN'          , type: 'text' },
	{ order: 2, filter: 'Customer Name'   , type: 'text' },
	{ order: 3, filter: 'Customer Surname', type: 'text' },
	{ order: 4, filter: 'ID Number'       , type: 'text' }
];

export class CustomerViewDetails {
	public static getSimTableColumns() {
		const simColumns: SimTableColumn[] = [
			{
				columnDef: 'msisdn',
				header: 'MSISDN',
				cell: (element: Msisdn) => `${element.msisdn}`,
				isMsisdn: true
			},
			{
				columnDef: 'iccid',
				header: 'ICCID',
				cell: (element: Msisdn) => `${element.iccid}`
			},
			{
				columnDef: 'contractType',
				header: 'Contract',
				cell: (element: Msisdn) => `${element.contractType}`
			},
			{
				columnDef: 'serviceType',
				header: 'Service',
				cell: (element: Msisdn) => `${element.serviceType}`
			},
			{
				columnDef: 'status',
				header: 'Status',
				cell: (element: Msisdn) => `${element.status}`,
				isSIMStatus: true
			},
			{
				columnDef: 'approvalStatus',
				header: 'Approval Status',
				cell: (element: Msisdn) => `${element.approvalStatus}`,
				isApprovalStatus: true
			},
			{
				columnDef: 'activation',
				header: 'Activation',
				cell: (element: Msisdn) => `${element.activation}`
			},
			{
				columnDef: 'activationDatetime',
				header: 'Act. Date',
				cell: (element: Msisdn) => `${element.activationDatetime}`,
				isDateTime: true
			},
			{
				columnDef: 'activationError',
				header: 'Act. Error',
				cell: (element: Msisdn) => `${element.activationError}`,
				isPopupView: true,
				fullHeader: 'Activation Error'
			},
			{
				columnDef: 'agent',
				header: 'Agent',
				cell: (element: Msisdn) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}`
			}
		];
		return simColumns;
	}

	public static getCardTableColumns() {
		const cardColumns: CardTableColumn[] = [
			{
				columnDef: 'id',
				header: 'ID',
				cell: (element: Card) => `${element.id}`
			},
			{
				columnDef: 'idType',
				header: 'ID Type',
				cell: (element: Card) => `${element.idType}`
			},
			{
				columnDef: 'cardPrintDatetime',
				header: 'Print',
				cell: (element: Card) => `${element.cardPrintDatetime}`,
				isDateTime: true
			},
			{
				columnDef: 'cardReprintDatetime',
				header: 'Reprint',
				cell: (element: Card) => `${element.cardReprintDatetime}`,
				isDateTime: true
			},
			{
				columnDef: 'cardStatus',
				header: 'Status',
				cell: (element: Card) => `${element.cardStatus}`
			},
			{
				columnDef: 'cardStatusDatetime',
				header: 'Status Date',
				cell: (element: Card) => `${element.cardStatusDatetime}`,
				isDateTime: true
			},
		];
		return cardColumns;
	}
}

export interface CustomerTableColumn {
	columnDef: string;
	header: string;
	bulkEditable: boolean;
	cell: (element: any) => any;
	isCheckbox?: boolean;
	isImage?: boolean;
	isMsisdn?: boolean;
	isDateTime?: boolean;
	isApprovalStatus?: boolean;
	isDocuments?: boolean;
}
export const CustomerTableColumns: CustomerTableColumn[] = [
	{
		columnDef: 'checkbox',
		header: '',
		bulkEditable: false,
		cell: (element: any) => `${element.selected}`,
		isCheckbox: true
	},
	{
		columnDef: 'customerPortrait',
		header: '',
		bulkEditable: false,
		cell: (element: any) => `${element.imageUrl}`,
		isImage: true
	},
	{
		columnDef: 'axonId',
		header: 'Account Number',
		bulkEditable: false,
		cell: (element: any) => `${element.axonId}`
	},
	{
		columnDef: 'msisdn',
		header: 'MSISDN',
		bulkEditable: false,
		cell: (element: any) => `${element.msisdn}`,
		isMsisdn: true
	},
	{
		columnDef: 'profileType',
		header: 'Profile Type',
		bulkEditable: false,
		cell: (element: any) => `${element.profileType}`
	},
	{
		columnDef: 'type',
		header: 'Type',
		bulkEditable: false,
		cell: (element: any) => `${element.type}`
	},
	{
		columnDef: 'originalDatetime',
		header: 'Datetime',
		bulkEditable: false,
		cell: (element: any) => `${element.originalDatetime}`,
		isDateTime: true
	},
	{
		columnDef: 'customerFullName',
		header: 'Full Name',
		bulkEditable: true,
		cell: (element: any) => `${element.customerFullName}`
	},
	{
		columnDef: 'id',
		header: 'ID Number',
		bulkEditable: true,
		cell: (element: any) => `${element.id}`
	},
	{
		columnDef: 'idType',
		header: 'ID Type',
		bulkEditable: false,
		cell: (element: any) => `${element.idType}`
	},
	{
		columnDef: 'approvalStatus',
		header: 'Status',
		bulkEditable: false,
		cell: (element: any) => `${element.approvalStatus}`,
		isApprovalStatus: true
	},
	{
		columnDef: 'originalAgent.name+originalAgent.surname',
		header: 'Agent',
		bulkEditable: false,
		cell: (element: any) => `${element.originalAgent ? AxonUtils.getAgentFullname(element.originalAgent) : ''}`
	},
	{
		columnDef: 'originalAgent.msisdn',
		header: 'Agent MSISDN',
		bulkEditable: false,
		cell: (element: any) => `${element.originalAgent.msisdn}`
	},
	{
		columnDef: 'captureAgent.name+captureAgent.surname',
		header: 'Modifier',
		bulkEditable: false,
		cell: (element: any) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}`
	},
	{
		columnDef: 'captureAgent.msisdn',
		header: 'Modifier MSISDN',
		bulkEditable: false,
		cell: (element: any) => `${element.captureAgent.msisdn}`
	},
	{
		columnDef: 'approvalAgent.name+approvalAgent.surname',
		header: 'Approver',
		bulkEditable: false,
		cell: (element: any) => `${element.approvalAgent ? AxonUtils.getAgentFullname(element.approvalAgent) : ''}`
	},
	{
		columnDef: 'approvalAgent.msisdn',
		header: 'Approver MSISDN',
		bulkEditable: false,
		cell: (element: any) => `${element.approvalAgent ? element.approvalAgent.msisdn : ''}`
	},
	{
		columnDef: 'images',
		header: 'Documents',
		bulkEditable: false,
		cell: (element: Customer) => {
			const colors = ['red', 'maroon', 'teal', 'navy', 'silver', 'yellow'];

			const out = [];
			element.availableImageTypes.sort().forEach(imageType => {

				let initials = '';
				imageType.split('_').forEach(word => {
					initials += word.charAt(0).toUpperCase();
				});

				let initialNumberVal = 0;
				for (let i = 0; i < initials.length; i++) {
					const val = initials[i].charCodeAt(0) - 97;
					initialNumberVal += val;
				}
				const colorIndex = Math.abs(initialNumberVal) % colors.length;

				const document = {
					title: imageType,
					initial: initials,
					color: colors[colorIndex]
				};

				out.push(document);
			});
			return out;
		},
		isDocuments: true
	}
];

export interface CustomerHistoryTableColumn {
	columnDef: string;
	header: string;
	cell: (element: any) => any;
	isDateTime?: boolean;
	isApprovalStatus?: boolean;
	isNoWrap?: boolean;
	isDownloadPdf?: boolean;
	isMsisdn?: boolean;
}
export const customerHistoryColumns: CustomerHistoryTableColumn[] = [
	{
		columnDef: 'msisdn'
		, header: 'MSISDN'
		, cell: (element: Customer) => `${element.msisdn}`,
		isMsisdn: true
	},
	{
		columnDef: 'pinref',
		header: 'Pinref',
		cell: (element: Customer) => `${element.pinref}`
	},
	{
		columnDef: 'axonId',
		header: 'Axon ID',
		cell: (element: Customer) => `${element.axonId}`
	},
	{
		columnDef: 'submissionDatetime',
		header: 'Datetime',
		cell: (element: Customer) => `${element.submissionDatetime}`,
		isDateTime: true
	},
	{
		columnDef: 'name',
		header: 'First Name',
		cell: (element: Customer) => `${element.name}`
	},
	{
		columnDef: 'otherName',
		header: 'Middle Name',
		cell: (element: Customer) => `${element.otherName}`
	},
	{
		columnDef: 'surname',
		header: 'Last Name',
		cell: (element: Customer) => `${element.surname}`
	},
	{
		columnDef: 'id',
		header: 'ID',
		cell: (element: Customer) => `${element.id}`
	},
	{
		columnDef: 'idType',
		header: 'ID Type',
		cell: (element: Customer) => `${element.idType}`
	},
	{
		columnDef: 'approvalStatus',
		header: 'Status',
		cell: (element: Customer) => `${element.approvalStatus}`,
		isApprovalStatus: true
	},
	{
		columnDef: 'recordAction',
		header: 'Record Action',
		cell: (element: Customer) => `${element.recordAction}`
	},
	{
		columnDef: 'recordType',
		header: 'Record Type',
		cell: (element: Customer) => `${element.recordType}`
	},
	{
		columnDef: 'channel',
		header: 'Channel',
		cell: (element: Customer) => getApp(element.submissionApp)
	},
	{
		columnDef: 'uploadStatus',
		header: 'Upload Status',
		cell: (element: Customer) => `${element.uploadStatus}`
	},
	{
		columnDef: 'capAgent',
		header: 'Agent',
		cell: (element: Customer) => `${element.captureAgent ? AxonUtils.getAgentFullname(element.captureAgent) : ''}`
	},
	{
		columnDef: 'capAgentMsisdn',
		header: 'Agent MSISDN',
		cell: (element: Customer) => `${element.captureAgent ? element.captureAgent.msisdn : ''}`
	},
	{
		columnDef: 'comment',
		header: 'Comment',
		cell: (element: Customer) => `${element.comment}`,
		isNoWrap: true
	},
	{
		columnDef: 'captureApp',
		header: 'Capture App',
		cell: (element: Customer) => getApp(element.captureApp)
	},
	{
		columnDef: 'pdf',
		header: 'Download PDF',
		cell: (element: Customer) => {
			if (element.images === undefined || element.images.length === 0) {
				return null;
			}
			return element.pinref;
		},
		isDownloadPdf: true
	}
];

export const customerHistoryFilterOptions: FilterOption[] = [
	{ order: 0, filter: 'Pinref', type: 'text' },
	{ order: 1, filter: 'MSISDN', type: 'text' },
	{ order: 2, filter: 'Agent Username', type: 'text' },
	{ order: 3, filter: 'First Name', type: 'text' },
	{ order: 4, filter: 'Middle Name', type: 'text' },
	{ order: 5, filter: 'Last Name', type: 'text' }
];

const getApp = (appChannel: string) => {
	if (appChannel !== undefined) {
		appChannel = (appChannel === 'roid') ? 'Android' : appChannel;
		return appChannel[0].toUpperCase() + appChannel.slice(1);
	}
	return "";
}

export interface SimSwapDynamicFieldConfig {
	id: number,
	msisdn: number,
	iccid: number,
	imsi: number
}
export const SimSwapConfig: SimSwapDynamicFieldConfig = {
	id: 9,
	msisdn: 53,
	iccid: 54,
	imsi: 60
}

//Put list of image types from image_type in this array
export const AllowedImageTypesForImageEdit = [];